import {
  LEGAL_SETTINGS_MODULE_CONFIG,
  LegalSettingsModuleConfig,
} from '@tremaze/shared/feature/legal-settings/module-config';
import {
  INSTITUTION_MODULE_CONFIG,
  InstitutionModuleConfig,
} from '@tremaze/shared/feature/institution/module-config';
import {
  INFORMATION_MODULE_CONFIG,
  InformationModuleConfig,
} from '@tremaze/shared/feature/information/module-config';
import {
  EVENT_MODULE_CONFIG,
  EventModuleConfig,
} from '@tremaze/shared/feature/event/module-config';
import {
  USER_MODULE_CONFIG,
  UserModuleConfig,
} from '@tremaze/shared/feature/user/module-config';
import {
  MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
  MatProgressSpinnerDefaultOptions,
} from '@angular/material/progress-spinner';
import { DEFAULT_CURRENCY_CODE, Provider } from '@angular/core';
import {
  CATEGORY_MODULE_CONFIG,
  CategoryModuleConfig,
} from '@tremaze/shared/feature/category/module-config';
import {
  FullTenantConfig,
  TenantConfigService,
} from '@tremaze/shared/tenant-config';
import { MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS } from '@ngxmc/color-picker';

function buildInformationCategoryModuleConfig(
  config: FullTenantConfig,
): CategoryModuleConfig {
  return {
    iconDisabled: true,
    colorDisabled: true,
    descriptionDisabled: true,
    automaticallyAssignAuthUserInstitutionOnCreation: !config.tremazeCustomer,
    disableDepartmentAllocation: config.tremazeCustomer,
    disableUserTypeAllocation: config.tremazeCustomer,
  };
}

export function buildEventCategoryModuleConfig(
  config: FullTenantConfig,
): CategoryModuleConfig {
  return {
    titleImageDisabled: true,
    descriptionDisabled: config.tremazeCustomer,
    automaticallyAssignAuthUserInstitutionOnCreation: !config.tremazeCustomer,
    disableDepartmentAllocation: config.tremazeCustomer,
    disableUserTypeAllocation: config.tremazeCustomer,
  };
}

function buildLegalModuleConfig(
  config: FullTenantConfig,
): LegalSettingsModuleConfig {
  return {
    hidePrivacySettingsApplyToAllCheckbox: true,
    hideDisclaimerOfLiabilitySetting: true,
    hideImprintSettingsApplyToAllCheckbox: true,
    hideAccessibilityTextSetting: config.tremazeCustomer,
    hideTermsOfUseSetting: config.tremazeCustomer,
  };
}

function buildInstitutionModuleConfig(
  config: FullTenantConfig,
): InstitutionModuleConfig {
  return {
    enableWorkflow: !config.tremazeCustomer,
    disableDashboard: true,
    disableEventDashboard: true,
    disableSubdomainSettings: true,
    disableDepartments: config.tremazeCustomer,
  };
}

function buildInformationModuleConfig(
  config: FullTenantConfig,
): InformationModuleConfig {
  return {
    publicSetting: config.publicInformation,
    hasGoalsEnabled: config.isGoalsEnabled,
    automaticallyAssignAuthUserInstitutionOnCreation: false,
    departmentAllocationDisabled: config.tremazeCustomer,
    userAllocationDisabled: config.tremazeCustomer,
    commentsDisabled: config.tremazeCustomer,
    visibleOnlyForEmployeesDisabled: config.tremazeCustomer,
  };
}

function buildEventModuleConfig(config: FullTenantConfig): EventModuleConfig {
  return {
    scheduleDisabled: config.disabledFeatures.has('EVENT_SCHEDULE'),
    eventFilesDisabled: true,
    emergencyContactDisabled: true,
    externalProviderDisabled: true,
    holidayDisabled: true,
    parentConfirmationDisabled: true,
    participationCriteriaDisabled: true,
    paymentDisabled: true,
    priceLevelDisabled: true,
    ticketsDisabled: true,
    highlightsDisabled: !config.tremazeCustomer,
    pricesDisabled: true,
    countRegistrationsInListDisabled: false,
    visibleForFamilySettingEnabled: !config.tremazeCustomer,
    genderSettingsDisabled: true,
    ageRestrictionsDisabled: true,
    publicEvents: config.publicEvents,
    automaticallyAssignAuthUserOnCreation: !config.tremazeCustomer,
    organizerDisabled: true,
    automaticallyAssignAuthUserInstitutionOnCreation: false,
    departmentAllocationDisabled: config.tremazeCustomer,
    registrationDisabled: config.tremazeCustomer,
    showCredits: false,
    usersDisabled: config.tremazeCustomer,
    videoMeetingsDisabled: config.tremazeCustomer,
    workshopsEnabled: false,
    signOnOffDateDisabled: config.tremazeCustomer,
    visibleOnlyForEmployeesSettingDisabled: config.tremazeCustomer,
  };
}

const userModuleConfig: UserModuleConfig = {
  enableSetPasswordOnUserCreation: true,
  enableReferencePersonSettings: true,
  disableUserInstitutionController: false,
};

const matProgressSpinnerConfig: MatProgressSpinnerDefaultOptions = {
  diameter: 25,
};

export const MODULE_CONFIG_PROVIDERS: Provider[] = [
  {
    provide: INSTITUTION_MODULE_CONFIG,
    useFactory: ({ config }: TenantConfigService) => {
      return buildInstitutionModuleConfig(config);
    },
    deps: [TenantConfigService],
  },
  // @angular-material-components/color-picker
  {
    provide: MAT_COLOR_FORMATS,
    useValue: NGX_MAT_COLOR_FORMATS,
  },
  {
    provide: INFORMATION_MODULE_CONFIG,
    useFactory: ({ config }: TenantConfigService) => {
      return buildInformationModuleConfig(config);
    },
    deps: [TenantConfigService],
  },
  {
    provide: CATEGORY_MODULE_CONFIG,
    useFactory: ({ config }: TenantConfigService) => {
      return buildInformationCategoryModuleConfig(config);
    },
    deps: [TenantConfigService],
  },
  {
    provide: EVENT_MODULE_CONFIG,
    useFactory: ({ config }: TenantConfigService) => {
      return buildEventModuleConfig(config);
    },
    deps: [TenantConfigService],
  },
  {
    provide: USER_MODULE_CONFIG,
    useValue: userModuleConfig,
  },
  {
    provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
    useValue: matProgressSpinnerConfig,
  },
  {
    provide: LEGAL_SETTINGS_MODULE_CONFIG,
    useFactory: ({ config }: TenantConfigService) => {
      return buildLegalModuleConfig(config);
    },
    deps: [TenantConfigService],
  },
  {
    provide: DEFAULT_CURRENCY_CODE,
    useValue: 'EUR',
  },
];
